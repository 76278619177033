import React , { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Menu.css";
// import Particles from 'react-particles-js';
import Typed from "react-typed";

const Projects=() => {
    return (
        <div class="aboutformat">
                <h2 class="title">Projects</h2>
                <h4 class="subtitle">Coming soon..</h4>
                <br/>
        </div>
    )
}
export default Projects
