import './App.css';
import Routes from "./Routes";
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
      <Routes/>
    </div>
  
  );
}

export default App;
